export default {
	methods: {
		openMenu(content){
			if (this.$store.state.ui.content != content) {
				this.$store.commit('UI_SET_CONTENT', {content: content});
			}
		}
	},
	computed:{
		getTotalRecherche(){
			return this.$store.getters.getTotalRecherche
		},
		getTotalFavoris(){
			return this.$store.getters.getTotalFavoris
		},
		getTotalSurfaces(){
			return this.$store.getters.getTotalSurfaces
		},
		getTotalParcelleFavoris(){
			return this.$store.state.parcelle.favoris && this.$store.state.parcelle.favoris.length ? this.$store.state.parcelle.favoris.length : 0
		},
	}
}